









































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import Logo from "@/components/logo.vue";
import Loading from "@/components/loading.vue";
@Component({
  components: { Logo, Loading },
})
export default class Home extends Vue {
  public fb: any;
  public pages: [] = [];
  public logged = false;
  public loading = false;
  public callbackUrl = "";
  public access_token = "";
  public connected_page_id = "";

  public canConnect(pageId: any) {
    return this.connected_page_id ? this.connected_page_id !== pageId : true;
  }

  public canInteract(pageId: any) {
    return this.connected_page_id && this.connected_page_id !== pageId
      ? false
      : true;
  }

  public fbInit() {
    this.fb = (window as any).FB;
    this.$forceUpdate();

    this.fb.init({
      appId: process.env.VUE_APP_ID,
      cookie: true,
      xfbml: true,
      version: "v11.0",
    });

    this.fb.getLoginStatus((data: any) => {
      if (data.status !== "connected") {
        this.logged = false;
        setTimeout(() => {
          return true;
        }, 900);

        return;
      }
      if (data.status === "connected" && data.authResponse) {
        this.logged = true;
        this.fb.api(
          `/${data.authResponse.userID}/accounts`,
          (response: any) => {
            if (response && !response.error) {
              this.pages = response.data;
            }
          }
        );
      }
    });
  }
  public connect(page: any) {
    this.loading = page.id;
    axios
      .post(
        process.env.VUE_APP_CONNECT_ENDPOINT,
        {
          page_id: page.id,
          access_token: this.fb.getAuthResponse().accessToken,
        },
        {
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        }
      )
      .then((res) => {
        if (res.data && res.data.response.fb_page_name) {
          window.location.href = this.callbackUrl + `?page=tpay-menu&page_name=${res.data.response.fb_page_name}&page_id=${page.id}`;
        }
      })
      .catch(() => {
        this.loading = false;
      });
  }

  public disconnect(page: any) {
    this.loading = page.id;
    axios
      .delete(process.env.VUE_APP_CONNECT_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
        data: {
          page_id: page.id,
          access_token: this.fb.getAuthResponse().accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.response === true) {
          window.location.href = this.callbackUrl + `?page=tpay-menu&page_name=&page_id=`;
        }
      })
      .catch(() => {
        this.loading = false;
      });
  }

  public logIn() {
    this.fb.login(
      (data: any) => {
        if (!data.authResponse) {
          return;
        }
        this.fb.api(
          `/${data.authResponse.userID}/accounts`,
          (response: any) => {
            if (response && !response.error) {
              /* handle the result */
              this.logged = true;
              this.pages = response.data;
            }
          }
        );
      },
      {
        scope:
          "pages_manage_metadata,pages_messaging",
        return_scopes: true,
      }
    );
  }

  public logout() {
    this.loading = false;
    this.fb.logout((response: any) => {
      if (!response.authResponse) {
        this.pages = [];
        this.logged = false;
        this.loading = true;
      }
    });
  }

  public parseUrlParams() {
    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    this.callbackUrl = url.searchParams.get("callback_url") || "";
    this.access_token = url.searchParams.get("access_token") || "";
    this.connected_page_id = url.searchParams.get("connected_page_id") || "";

    if (!this.callbackUrl) {
      alert("Brak parametru callback_url");
      return;
    }
  }

  public created() {
    if ((window as any).FB) {
      return this.fbInit();
    }
    (window as any).fbAsyncInit = this.fbInit.bind(this);

    this.parseUrlParams();
    
    // tslint:disable-next-line:only-arrow-functions
    (function (d: any, s: any, id: any) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
}
